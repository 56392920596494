import _tonalNote from "tonal-note";
import _tonalPcset from "tonal-pcset";
import _tonalDistance from "tonal-distance";
import _tonalDictionary from "tonal-dictionary";
import _tonalArray from "tonal-array";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var tonalNote = _tonalNote;
var tonalPcset = _tonalPcset;
var tonalDistance = _tonalDistance;
var tonalDictionary = _tonalDictionary;
var tonalArray = _tonalArray;
/**
 * [![npm version](https://img.shields.io/npm/v/tonal-scale.svg?style=flat-square)](https://www.npmjs.com/package/tonal-scale)
 *
 * A scale is a collection of pitches in ascending or descending order.
 *
 * This module provides functions to get and manipulate scales.
 *
 * @example
 * // es6
 * import * as Scale from "tonal-scale"
 * // es5
 * const Scale = require("tonal-scale");
 *
 * @example
 * Scale.notes("Ab bebop") // => [ "Ab", "Bb", "C", "Db", "Eb", "F", "Gb", "G" ]
 * Scale.names() => ["major", "minor", ...]
 * @module Scale
 */

var NO_SCALE = Object.freeze({
  name: null,
  intervals: [],
  names: [],
  chroma: null,
  setnum: null
});

var properties = function (name) {
  var intervals = tonalDictionary.scale(name);

  if (!intervals) {
    return NO_SCALE;
  }

  var s = {
    intervals: intervals,
    name: name
  };
  s.chroma = tonalPcset.chroma(intervals);
  s.setnum = parseInt(s.chroma, 2);
  s.names = tonalDictionary.scale.names(s.chroma);
  return Object.freeze(s);
};

var memoize = function (fn, cache) {
  return function (str) {
    return cache[str] || (cache[str] = fn(str));
  };
};
/**
 * Get scale properties. It returns an object with:
 * - name: the scale name
 * - names: a list with all possible names (includes the current)
 * - intervals: an array with the scale intervals
 * - chroma:  scale croma (see pcset)
 * - setnum: scale chroma number
 *
 * @function
 * @param {string} name - the scale name (without tonic)
 * @return {Object}
 */


var props = memoize(properties, {});
/**
 * Return the available scale names
 *
 * @function
 * @param {boolean} [aliases=false] - true to include aliases
 * @return {Array} the scale names
 *
 * @example
 * Scale.names() // => ["maj7", ...]
 */

var names = tonalDictionary.scale.names;
/**
 * Given a scale name, return its intervals. The name can be the type and
 * optionally the tonic (which is ignored)
 *
 * It retruns an empty array when no scale found
 *
 * @function
 * @param {string} name - the scale name (tonic and type, tonic is optional)
 * @return {Array<string>} the scale intervals if is a known scale or an empty
 * array if no scale found
 * @example
 * Scale.intervals("major") // => [ "1P", "2M", "3M", "4P", "5P", "6M", "7M" ]
 */

var intervals = function (name) {
  var p = tokenize(name);
  return props(p[1]).intervals;
};
/**
 * Get the notes (pitch classes) of a scale.
 *
 * Note that it always returns an array, and the values are only pitch classes.
 *
 * @function
 * @param {string} tonic
 * @param {string} nameOrTonic - the scale name or tonic (if 2nd param)
 * @param {string} [name] - the scale name without tonic
 * @return {Array} a pitch classes array
 *
 * @example
 * Scale.notes("C", "major") // => [ "C", "D", "E", "F", "G", "A", "B" ]
 * Scale.notes("C major") // => [ "C", "D", "E", "F", "G", "A", "B" ]
 * Scale.notes("C4", "major") // => [ "C", "D", "E", "F", "G", "A", "B" ]
 * Scale.notes("A4", "no-scale") // => []
 * Scale.notes("blah", "major") // => []
 */


function notes(nameOrTonic, name) {
  var p = tokenize(nameOrTonic);
  name = name || p[1];
  return intervals(name).map(tonalDistance.transpose(p[0]));
}
/**
 * Check if the given name is a known scale from the scales dictionary
 *
 * @function
 * @param {string} name - the scale name
 * @return {Boolean}
 */


function exists(name) {
  var p = tokenize(name);
  return tonalDictionary.scale(p[1]) !== undefined;
}
/**
 * Given a string with a scale name and (optionally) a tonic, split
 * that components.
 *
 * It retuns an array with the form [ name, tonic ] where tonic can be a
 * note name or null and name can be any arbitrary string
 * (this function doesn"t check if that scale name exists)
 *
 * @function
 * @param {string} name - the scale name
 * @return {Array} an array [tonic, name]
 * @example
 * Scale.tokenize("C mixolydean") // => ["C", "mixolydean"]
 * Scale.tokenize("anything is valid") // => ["", "anything is valid"]
 * Scale.tokenize() // => ["", ""]
 */


function tokenize(str) {
  if (typeof str !== "string") {
    return ["", ""];
  }

  var i = str.indexOf(" ");
  var tonic = tonalNote.name(str.substring(0, i)) || tonalNote.name(str) || "";
  var name = tonic !== "" ? str.substring(tonic.length + 1) : str;
  return [tonic, name.length ? name : ""];
}
/**
 * Find mode names of a scale
 *
 * @function
 * @param {string} name - scale name
 * @example
 * Scale.modeNames("C pentatonic") // => [
 *   ["C", "major pentatonic"],
 *   ["D", "egyptian"],
 *   ["E", "malkos raga"],
 *   ["G", "ritusen"],
 *   ["A", "minor pentatonic"]
 * ]
 */


var modeNames = function (name) {
  var ivls = intervals(name);
  var tonics = notes(name);
  return tonalPcset.modes(ivls).map(function (chroma, i) {
    var name = tonalDictionary.scale.names(chroma)[0];

    if (name) {
      return [tonics[i] || ivls[i], name];
    }
  }).filter(function (x) {
    return x;
  });
};
/**
 * Get all chords that fits a given scale
 *
 * @function
 * @param {string} name - the scale name
 * @return {Array<string>} - the chord names
 *
 * @example
 * Scale.chords("pentatonic") // => ["5", "64", "M", "M6", "Madd9", "Msus2"]
 */


var chords = function (name) {
  var inScale = tonalPcset.isSubsetOf(intervals(name));
  return tonalDictionary.chord.names().filter(function (name) {
    return inScale(tonalDictionary.chord(name));
  });
};
/**
 * Given an array of notes, return the scale: a pitch class set starting from
 * the first note of the array
 *
 * @function
 * @param {Array} notes
 * @return {Array}
 * @example
 * Scale.toScale(['C4', 'c3', 'C5', 'C4', 'c4']) // => ["C"]
 * Scale.toScale(['D4', 'c#5', 'A5', 'F#6']) // => ["D", "F#", "A", "C#"]
 */


var toScale = function (notes) {
  var pcset = tonalArray.compact(notes.map(tonalNote.pc));

  if (!pcset.length) {
    return pcset;
  }

  var tonic = pcset[0];
  var scale = tonalArray.unique(pcset);
  return tonalArray.rotate(scale.indexOf(tonic), scale);
};
/**
 * Get all scales names that are a superset of the given one
 * (has the same notes and at least one more)
 *
 * @function
 * @param {string} name
 * @return {Array} a list of scale names
 * @example
 * Scale.supersets("major") // => ["bebop", "bebop dominant", "bebop major", "chromatic", "ichikosucho"]
 */


var supersets = function (name) {
  if (!intervals(name).length) {
    return [];
  }

  var isSuperset = tonalPcset.isSupersetOf(intervals(name));
  return tonalDictionary.scale.names().filter(function (name) {
    return isSuperset(tonalDictionary.scale(name));
  });
};
/**
 * Find all scales names that are a subset of the given one
 * (has less notes but all from the given scale)
 *
 * @function
 * @param {string} name
 * @return {Array} a list of scale names
 *
 * @example
 * Scale.subsets("major") // => ["ionian pentatonic", "major pentatonic", "ritusen"]
 */


var subsets = function (name) {
  var isSubset = tonalPcset.isSubsetOf(intervals(name));
  return tonalDictionary.scale.names().filter(function (name) {
    return isSubset(tonalDictionary.scale(name));
  });
};

exports.props = props;
exports.names = names;
exports.intervals = intervals;
exports.notes = notes;
exports.exists = exists;
exports.tokenize = tokenize;
exports.modeNames = modeNames;
exports.chords = chords;
exports.toScale = toScale;
exports.supersets = supersets;
exports.subsets = subsets;
export default exports;
export const __esModule = exports.__esModule;
const _props = exports.props,
      _names = exports.names,
      _intervals = exports.intervals,
      _notes = exports.notes,
      _exists = exports.exists,
      _tokenize = exports.tokenize,
      _modeNames = exports.modeNames,
      _chords = exports.chords,
      _toScale = exports.toScale,
      _supersets = exports.supersets,
      _subsets = exports.subsets;
export { _props as props, _names as names, _intervals as intervals, _notes as notes, _exists as exists, _tokenize as tokenize, _modeNames as modeNames, _chords as chords, _toScale as toScale, _supersets as supersets, _subsets as subsets };